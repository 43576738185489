/**
 * Edge Learn post links
 * Make the entire post excerpt box a link to the post single
 * Script loaded via Elementor Edge Learn Loop template
 * ===========================================================
 */


const edgeLearnPostLinks = () => {
    const posts = document.getElementsByClassName('elementor-post');
    for (let post of posts) {
        if (!post.classList.contains('edge-learn-post-link')) {
            post.classList.add('edge-learn-post-link');
            post.addEventListener('click', (e) => {
               if (e.target.classList.contains('elementor-widget-wrap')) {
                   const link = post.querySelector('h2 a');
                   window.location = link.href;
               }
            });
        }
    }
}

edgeLearnPostLinks();
